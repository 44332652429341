.sidebar_border {
  position: relative;
  padding: 12px 16px;
  border-radius: 30px;
  background: #24212f;
  z-index: 1;
  color: white;
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.sidebar_border::before {
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  border-radius: 30px;
  background: #24212f;
  z-index: -1;
}

.normal-border {
  background: #24212f;
  color: white;
}
.active-border {
  background: linear-gradient(to right, #6f86ff, #fc7588);
  color: #fff;
}
.searchbar_shadow {
  box-shadow: 0 0 8px rgba(255, 73, 128, 0.5), 0 0 8px rgba(255, 73, 128, 0.2),
    0 0 8px rgba(79, 86, 255, 0.3);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.react-calendar__navigation {
  display: none !important;
}

.custom-calendar {
  --calendar-bg: rgba(255, 255, 255, 0.05) !important;
}

.custom-calendar .react-calendar__tile {
  background-color: #ffffff0d;
  /* background-color: var(--calendar-bg); */
  border-radius: 10px;
  transition: background-color 0.3s;
}

.custom-calendar .react-calendar__tile:enabled:hover,
.custom-calendar .react-calendar__tile:enabled:focus {
  background: linear-gradient(to right, #6f86ff, #fc7588);
}

.custom-calendar .react-calendar__tile--active {
  background: linear-gradient(to right, #6f86ff, #fc7588);
  color: #fff;
}

.custom-calendar .react-calendar__month-view__weekdays__weekday {
  color: #94a3b8;
  background-color: #1d1a27;
  font-weight: 400;
  font-family: Inter;
  text-decoration: none !important;
}
abbr:where([title]) {
  -webkit-text-decoration: none !important;
  text-decoration: none !important;
}
.react-calendar {
  width: 100% !important;
  max-width: 100%;
  background-color: #1d1a27 !important;
  border: none !important;
  font-family: Inter !important;
  line-height: 2.125em !important;
}

.custom-calendar .react-calendar__month-view__days__day--weekend {
  color: #ffffff;
  font-size: 12px;
  font-weight: 400 !important;
  font-family: Inter !important;
}
.react-calendar__tile {
  max-width: 100%;
  padding: 10px 0 !important;
  text-align: center;
  line-height: 10px !important;
  font: inherit;
  font-size: 12px !important;
}
.react-calendar__month-view__days {
  justify-content: center;
}
.profile-input {
  background: rgb(36 33 47 / var(--tw-bg-opacity));
  padding: 5px 10px;
  outline: none;
}

.profile-input > input {
  background: rgb(36 33 47 / var(--tw-bg-opacity));
  padding: 5px 10px;
  outline: none;
}
.PhoneInputCountrySelect {
  background: rgb(36 33 47 / var(--tw-bg-opacity));
  padding: 5px 10px;
  outline: none;
}
.custom-calendar .react-calendar__month-view__days {
  gap: 5px;
  justify-content: space-between;
}
.bg-gradient-blur-wrapper {
  opacity: 0.5;
  filter: blur(100px);
  width: 100%;
  max-width: 44%;
  min-height: 74%;
  position: absolute;
  /* z-index: 1; */
}

.bg-gradient-blur-circle-1 {
  background-color: #d220ff;
  border-radius: 1000000px;
  width: 70%;
  min-width: 70%;
  min-height: 70%;
  max-height: 70%;
  position: absolute;
}

.bg-gradient-blur-circle-1.purple {
  background-color: #6923ff;
}

.bg-gradient-blur-circle-1.purple-2 {
  background-color: var(--secondary-2);
}

.bg-gradient-blur-circle-1.purple-2.big {
  width: 87%;
  min-width: 87%;
  min-height: 87%;
  max-height: 87%;
  bottom: 0;
}

.bg-gradient-blur-circle-1.pink {
  background-color: #ff4895;
}

.bg-gradient-blur-circle-1.blue {
  background-color: #1766ff;
}

.bg-gradient-blur-circle-2 {
  background-color: #fe316f;
  border-radius: 1000000px;
  width: 70%;
  min-width: 70%;
  min-height: 70%;
  max-height: 70%;
  position: absolute;
  bottom: 0;
  right: 0;
}

.bg-gradient-blur-circle-2.blue {
  background-color: #1766ff;
}

.bg-gradient-blur-circle-2.blue.left {
  right: auto;
}

.bg-gradient-blur-circle-2.blue-2 {
  background-color: var(--secondary-4);
}

.bg-gradient-blur-circle-2.blue-2.blog {
  z-index: 1;
  left: 0;
  right: auto;
}

.bg-gradient-blur-circle-2.purple {
  background-color: #6923ff;
}

.bg-gradient-blur-circle-3 {
  background-color: #6923ff;
  border-radius: 1000000px;
  width: 70%;
  min-width: 70%;
  min-height: 70%;
  max-height: 70%;
  position: absolute;
  top: 0;
  right: 0;
}

.bg-gradient-blur-circle-3.pink {
  background-color: var(--secondary-1);
}

.bg-gradient-blur-circle-3.pink.big {
  width: 87%;
  min-width: 87%;
  min-height: 87%;
  max-height: 87%;
}

.bg-gradient-blur-circle-3.pink.team {
  top: -12px;
  right: -77px;
}

.bg-gradient-blur-circle-3.pink.top {
  z-index: 1;
  top: -41px;
  right: -112px;
}

.bg-gradient-blur-circle-3.not-found {
  z-index: 1;
}

.bg-gradient-blur-wrapper {
  opacity: 0.5;
  filter: blur(100px);
  width: 100%;
  max-width: 44%;
  min-height: 74%;
  position: absolute;
}
.bg-gradient-blur-wrapper.home-hero-1 {
  top: -230px;
  left: -209px;
}
.bg-gradient-blur-wrapper.home-hero-2 {
  bottom: 25px;
  right: -212px;
}

.bg-animate {
  transform-style: preserve-3d;
  will-change: transform;
  animation-name: animate;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-delay: 0ms;
}

@keyframes animate {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
  50% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(180deg) skew(0deg, 0deg);
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
      rotateY(0deg) rotateZ(360deg) skew(0deg, 0deg);
  }
}
