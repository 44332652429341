@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  font-family: "Inter", sans-serif;
}
.bg_gradient {
  background: linear-gradient(to right, #6f86ff, #fc7588);
}

.white_border4 {
  border: 1px solid rgba(255, 255, 255, 0.04);
  box-shadow: 0 3px 7px 0px rgba(19, 17, 26, 0.14);
}

.gradient_border {
  position: relative;
  background: linear-gradient(to right, #6f86ff, #fc7588);
  z-index: 1;
}
.gradient_border::before {
  content: "";
  position: absolute;
  top: 1px;
  bottom: 1px;
  left: 1px;
  right: 1px;
  border-radius: 30px;
  background: #1d1a27;
  z-index: -1;
}

.reset h1,
.reset h2,
.reset h3,
.reset h4,
.reset h5,
.reset h6 {
  all: revert;
}
